.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  padding: 20px 100px;
  position: relative;
  background-color: #fff;
  box-shadow: 3px -7px 15px rgba(0, 0, 0, 0.1);
}

.footerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 10px;
  /* width: 70%; */
}

.fcDesc h3 {
  color: #0f4d92;
}

.fLink {
  text-decoration: none;
  color: #000;
  font-family: sans-serif;
  transition: all 0.3s ease-out;
  /* margin-bottom: 3px; */
}

.fLink:hover {
  transform: translateY(-1px);
}

/* .fcDesc {
  width: 90%;
} */

.fcLinks {
  display: flex;
  gap: 80px;
  justify-content: space-between;
  width: 100%;
}

.socialMedia {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 100px;
  color: #0f4d92;
}

.fsIcon {
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.fsIcon:hover {
  transform: translateY(-1px);
}

.fsiLink {
  text-decoration: none;
  color: inherit;
  display: flex;
}

.resourcesLinks {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.resourcesLinks h4 {
  color: #0f4d92;
  margin-bottom: 0;
}

.resourcesLinks p {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0;
}

.copyWrite {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #1e314f;
  margin-top: 5px;
}

.policyLinks {
  display: flex;
  text-align: end;
  gap: 20px;
}

.copyRightText p {
  margin-bottom: 0;
}

.plLink {
  text-decoration: none;
  color: #000;
}

.plLink:hover {
  text-decoration: underline;
}

.policyLinks p {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .resourcesLinks {
    width: 140px;
  }

  .con {
    width: 250px;
  }

  .resourcesLinks h4 {
    width: 105px;
  }

  .copyWrite {
    flex-direction: column;
    gap: 5px;
    padding-top: 10px;
  }

  .copyRightText p {
    margin: 0;
  }

  .policyLinks p {
    margin: 0;
  }

  .fcLinks {
    gap: 20px;
  }
}

@media (max-width: 430px) {
  .footer {
    padding: 20px 20px;
    height: 100%;
  }

  .footerContent,
  .fcDesc {
    width: auto;
  }

  .fcDesc h3 {
    font-weight: 600;
  }

  .fcLinks {
    gap: 5px;
    flex-direction: column;
  }

  .socialMedia {
    /* flex-direction: column; */
    justify-content: space-evenly;
    width: auto;
  }

  .footerForm {
    display: none;
  }

  .resourcesLinks p {
    margin-bottom: 0;
  }
}