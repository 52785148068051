.insuranceHero {
  /* background: #354256; */
  background-color: #000;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.insuranceHeroDesc {
  /* padding: 0 75px; */
  top: 40%;
  /* width: 50%; */
  color: #fff;
  position: absolute;
  overflow: auto;
  height: 60%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.insuranceHeroDesc h1 {
  font-size: 3.2rem;
}

.insuranceHeroDesc h3 {
  margin: 0;
  /* background-color: #3542567f; */
  background-color: #0f4d9291;
  padding: 20px;
  font-size: 20px;
  font-weight: 500;
} 

.insuranceHeroImg {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 0.5;
  transform: scaleX(-1);
}

@media only screen and (max-width: 1024px) { 
  .insuranceHeroDesc h1 {
    font-size: 2.6rem;
  }
  
  .insuranceHeroDesc h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) { 
  .insuranceHeroDesc h1 {
    font-size: 2.2rem;
  }

  .insuranceHeroDesc h3 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 430px) { 
  .insuranceHeroDesc h1 {
    font-size: 2rem;
  }

  .insuranceHeroDesc h3 {
    font-size: 14px;
  }
}