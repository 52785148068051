.trustSec {
  display: flex;
  height: 60dvh;
  flex-direction: column;
  justify-content: space-evenly;
  /* background-color: #2e343c; */
  background-color: #0f4d92;
  color: #fff;
  padding: 20px 80px;
  position: relative;
}

.trustDesc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.trustDesc h2, .trustDesc h3 {
  margin: 0;
  color: #fff;
}

/* .trustDesc h2 {
  font-size: 40px !important;
} */

.swiper-button-next:after, .swiper-button-prev:after {
  color: #fff;
}

.trustList {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
}

.tListItem {
  display: flex;
  flex-direction: column;
}

.tIcon {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 65px;
}

.tIcon h4 {
  /* margin: 0; */
  font-size: 22px;
}

.tContent {
  padding: 0 65px;
}

@media (max-width: 1024px) {
  .trustSec {
    padding: 0 60px;
  }
}

@media (max-width: 768px) {
  .trustSec {
    padding: 0 100px;
  }
}

@media (max-width: 430px) {
  .trustSec {
    padding: 20px 20px;
    height: auto;
  }

  .trustDesc h3, .trustDesc h2{
    text-align: center;
  }

  .tContent p {
    margin: 0;
  }
}