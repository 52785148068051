/* .contactPHero {
  background: black;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
} */

/* .contactPHero img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 0.5;
  object-position: top;
} */

/* .chDesc {
  top: 45%;
  width: 45%;
  color: #fff;
  position: relative;
  overflow: auto;
  max-height: 100%;
  align-self: center;
} */

/* .chdTitle {
  font-size: 3.2rem;
  text-align: center;
}

.chdSubTitle {
  font-weight: 600;
  font-size: 2.2rem;
  border: none;
  text-align: center;
  color: #fff;
} */

.cpcs {
  padding-bottom: 0;
}

.contactPageContent {
  background-color: #0f4d92;
  padding-top: 20px;
}

.contactPageForm {
  width: 100%;
  box-shadow: none;
}

.contactPageForm .formDesc h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.contactPageForm .formDesc h4 {
  font-weight: 600;
}

.cPFrom form, .cPFrom input, .cPFrom textarea {
  border-radius: 10px;
  padding: 10px;
  font-size: 1.2rem;
}

.FAQs {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  padding: 0 100px;
  margin-top: 80px;
}

.FAQs h2 {
  border-bottom: none;
  /* border-left: 3px solid red; */
  padding-left: 3px;
}

.cpEndNote {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  padding: 0 100px;
}

.cpEndNote h3 {
  padding-left: 10px;
  /* border-left: 5px solid red; */
  font-size: 24px;
  text-align: justify;
}

@media only screen and (max-width: 1024px) {
  .chDesc {
    top: 35%;
    width: 51%;
  }

  .chdTitle {
    font-size: 2.6rem;
  }

  .cpEndNote h3 {
    font-size: 20px;
  }

  .contactPageForm .formDesc h3 {
    font-size: 18px;
  }

  .cPFrom form, .cPFrom input, .cPFrom textarea {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .chDesc {
    top: 40%;
    width: 58%;
  }

  .chdTitle {
    font-size: 2.2rem;
  }

  .FAQs,
  .cpEndNote {
    padding: 0 60px;
  }

  .cpEndNote h3 {
    font-size: 1rem;
  }

  .faq-question,
  .faq-answer {
    font-size: 1.2rem;
  }

  .cPFrom form, .cPFrom input, .cPFrom textarea {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 430px) {
  .chDesc {
    top: 35%;
    width: 95%;
  }

  .FAQs,
  .cpEndNote {
    padding: 0 20px;
  }

  .faq-question,
  .faq-answer {
    font-size: 1rem;
  }

  .faq-question {
    gap: 10px;
  }

  .contactPageForm .formDesc h3 {
    font-weight: 600;
  }
}