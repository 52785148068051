.singleInsuranceService {
  display: flex;
  flex-direction: column;
  margin-top: 75px;
}

.siSHero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: column; */
  padding: 0 100px;
}

.sishImg {
  height: 50vh;
}

.iSImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.sishTtitle {
  /* position: absolute;
  top: 20%; */
  font-size: 40px;
  margin-bottom: 0;
  padding-bottom: 5px;
  color: #0f4d92;
  /* border-bottom: 3px solid red; */
}

.siSHeroDesc h3,
.ourAppraoch h3 {
  font-weight: 500;
}

/* .insTContent {
  padding: 20px 100px;
} */

.siSHeroDesc {
  width: 50%;
}

/* .siSHeroDesc h3 {
  font-size: 24px;
  color: #666666;
} */

.ourAppraoch {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 0 100px;
  /* background: antiquewhite; */
  flex-direction: column;
}

.ourAppraoch h2 {
  /* width: 20%; */
  margin: 0;
  border-bottom: none;
  padding-bottom: 0;
  /* padding-left: 5px; */
  /* border-left: 3px solid red; */
  font-size: 36px;
}

.whyChooseInsurance {
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
}

.whyChooseInsurance h2 {
  font-size: 36px;
  align-self: center;
  width: fit-content;
}

.services {
  transform: skew(333deg) !important;
  width: 28% !important;
}

@media (max-width: 1024px) {
  .ourAppraoch h2 {
    font-size: 28px !important;
  }

  .sishTtitle {
    font-size: 34px;
  }

  .siSHeroDesc h3,
  .ourAppraoch h3 {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .singleInsuranceService {
    margin-top: 70px;
  }

  .siSHero {
    padding: 20px 80px;
  }

  .ourAppraoch {
    padding: 0 80px;
  }

  .siSHero {
    flex-direction: column;
  }

  .ourAppraoch h2 {
    font-size: 24px !important;
  }

  .siSHeroDesc {
    width: auto;
  }

  .siSHeroDesc h3,
  .ourAppraoch h3 {
    font-size: 16px;
  }

  .whyChooseInsurance {
    padding: 20px 80px;
  }
}

@media (max-width: 430px) {

  .sishImg {
    height: auto;
    margin-top: 10px;
  }

  .siSHero,
  .ourAppraoch {
    padding: 10px 20px;
  }

  .whyChooseInsurance {
    padding: 0 20px;
  }

  .siSHeroDesc h3 {
    /* text-align: center; */
    /* font-size: 1.2rem; */
    margin-bottom: 0;
  }

  .sishTtitle {
    font-size: 32px;
  }

  .ourAppraoch h2 {
    font-size: 18px !important;
  }

  .whyChooseInsurance h2 {
    font-size: 32px;
    text-align: center;
    margin-top: 0;
  }
}