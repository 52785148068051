.about {
  /* width: 100%; */
  /* height: 40vh; */
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 0 150px;
  height: 65dvh;
  /* justify-content: space-evenly; */
  justify-content: center;
  position: relative;
  background: #fff;
}

.aboutItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 90px 150px 35px; */
  /* flex: 2; */
  /* margin: 100px 0 20px; */
  text-align: justify;
}

.aboutTitle {
  align-self: center;
  margin-top: 0;
}

.aboutTrustedLogos {
  display: flex;
  justify-content: space-between;
  /* flex: 1; */
  /* padding: 0 120px; */
}

.trustedLogos {
  /* flex: 1; */
  max-width: 150px;
  max-height: 70px;
  object-fit: contain;
  outline: none;
}

.aboutContent {
  font-weight: 600;
  line-height: 1.8;
  font-size: 18px;
}

@media (max-width: 1024px) { 
  .about {
    padding: 0 120px;
    height: auto;
  }

  .aboutContent, .insuranceServices h3 {
    line-height: 1.3 !important;
  }

  .insuranceServices h3 {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) { 
  .about {
    padding: 0 100px;
    height: auto;
  }

  .aboutContent {
    margin: 0;
  }

  .trustedLogos {
    max-width: 100px;
  }
}


@media (max-width: 430px) { 
  .about {
    padding: 20px 20px;
    height: auto;
  }

  .trustedLogos {
    max-width: 50px;
  }

  .aboutContent {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
  }

  .aboutTitle {
    margin-top: 20px;
  }

  /* .aboutItem {
    margin: 100px 0 20px;
  } */
}