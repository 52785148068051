.FAQdiv {
  padding: 20px;
  margin: 10px 0;
  border-bottom: 2px solid rgba(128, 128, 128, 0.311);
  transition: border-left 0.3s ease, background 1s ease-in;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 8px 0;
}

.faq-answer {
  font-size: 16px;
}

.faq-answer {
  margin-top: 10px;
  max-height: 0;
  overflow: hidden;
  padding-left: 20px;
  transition: max-height 0.9s ease-in-out;
}

.faq-answer.active {
  max-height: 500px;
  /* Adjust the value to a reasonable max height for your content */
  transition: 1.9s ease-in-out;
  /* Add delay to the animation */
}

.ques-span {
  display: flex;
}

.FAQdiv:hover {
  border-left: 5px solid #0f4d92;
  background: #80808054;
}

.FAQdiv:hover .MuiSvgIcon-root {
  color: #0f4d92; /* Set your desired hover color here */
}


@media (max-width: 1024px) {
  .faq-answer {
    padding-left: 23px;
  }
}

@media (max-width: 768px) {
  .faq-answer {
    font-size: 14px;
    padding-left: 23px;
  }

  .faq-question {
    font-size: 16px;
  }
}