.testimonial {
  display: flex;
  height: auto;
  justify-content: space-evenly;
  padding: 20px 0;
  flex-direction: column;
  /* margin: 50px 0; */
  background: #0f4d92;
  /* background-color: #2e343c; */
  color: #fff;
  position: relative;
}

.testimonialDesc {
  display: flex;
  flex-direction: column;
  /* width: fit-content; */
  align-items: center;
}

.testimonial h2 {
  display: flex;
  justify-content: center;
  margin: 0;
  color: #fff;
  /* font-size: 36px; */
}

.testimonial h3 {
  display: flex;
  justify-content: center;
  font-weight: 300;
}

.testimonial-container {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
}

.testimonail-contnet {
  display: flex;
  padding: 0 120px;
  align-items: center;
  justify-content: center;
}

.people-quotes {
  /* background: linear-gradient(268deg, #4e1212, #56688f); */
  /* background: #05031f; */
  background-color: #fff;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  /* position: relative; */
  /* left: 5%; */
  /* top: 15%; */
  height: 20vh;
  box-shadow: 1px 1px 10px #000000;
  padding: 15px;
  /* gap: 10px; */
}

.quote-icon {
  background-color: bisque;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 25px;
  align-self: center;
}

.quote p {
  color: #212833;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  text-align: center;
}

.person-detail h4 {
  color: #212833;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}

/* .testimonialBg {
  position: relative;
  height: 350px;
} */

.testimonialBg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1024px) {
  .testimonialBg {
    max-width: 275px;
  }

  .testimonialBg img {
    width: 370px;
  }

  .quote p {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .testimonialBg {
    display: none;
  }

  .testimonail-contnet {
    padding: 0 100px;
  }
}

@media (max-width: 430px) {
  .testimonial {
    height: auto;
    padding: 10px;
    justify-content: unset;
    padding: 20px 0;
  }

  .testimonail-contnet {
    padding: 0 20px;
  }
}