.contactSection {
  height: auto;
  padding: 20px 0;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  position: relative;
  background-color: #fff;
}

.contactTitle {
  display: flex;
  justify-content: center;
}

.contactTitle h2 {
  margin-top: 0;
}

.contactContent {
  display: flex;
  padding: 0 100px;
  justify-content: space-evenly;
  /* width: 100%; */
  background-color: #fff;
  /* background-color: gray; */
  color: #000;
}

.contactDetails {
  /* width: 45%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contactDetails h3 {
  display: flex;
  align-items: center;
  justify-self: center;
  gap: 5px;
}

.form {
  width: 35%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  box-shadow: 4px 0px 10px -2px rgba(33, 40, 51, 0.6),
             -4px 0px 10px -2px rgba(33, 40, 51, 0.6);
  /* box-shadow: 4px -2px 10px 9px rgb(33 40 51 / 60%); */
  /* border-radius: 20px; */
  background-color: #0f4d92;
}

.formDesc {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
}

.formDesc h3 {
  margin: 0;
}

.formDesc h4 {
  font-weight: 300;
  font-size: 16px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

/* form input, textarea {
  padding: 10px;
  border: none;
  background-color: aliceblue;
  width: 90%;
} */

.cFContent {
  padding: 10px;
  border: none;
  background-color: aliceblue;
  width: 90%;
  font-size: 1rem;
}

.red {
  display: contents;
  color: red;
}

.contactBtn {
  background-color: #fff;
  color: #0f4d92;
  font-weight: 600;
}

.contactFormLabel {
  border: none;
  display: flex;
  width: 92%;
  color: #fff;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .contactSection {
    padding-bottom: 20px;
  }

  .contactDetails {
    width: 35%;
  }

  .form {
    width: 50%;
  }

  .contactDetails h3 {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .contactContent {
    padding: 0 60px;
  }

  .contactDetails {
    width: 40%;
  }
}

@media (max-width: 430px) { 
  .contactDetails {
    display: none;
  }

  .contactContent {
    padding: 0 20px;
  }

  .form {
    width: 85%;
  }
}