.icContent {
  background: #fff;
  position: relative;
  padding: 20px 100px;
}

.iccDesc h2 {
  margin: 0;
  border-bottom: none;
  padding-left: 5px;
  /* border-left: 5px solid red; */
  text-align: center;
}

.section {
  display: flex;
  align-items: center;
  margin: 20px 0;
  justify-content: space-between;
}

.heading {
  background-color: #0f4d92;
  padding: 15px;
  /* transform: skew(333deg); */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.heading h3 {
  transform: skew(27deg);
  margin: 0;
  color: white;
  /* padding: 15px; */
  font-size: 26px;
}

.content {
  padding: 20px;
  background-color: #f4f4f4;
  transform: skew(333deg);
  width: 65%;
}

.content p {
  transform: skew(19deg);
  font-size: 16px;
}

.category-buttons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.category-buttons button {
  background-color: transparent;
  color: #0f4d92;
  padding: 10px;
  cursor: pointer;
  border: none;
  font-weight: 600;
  font-size: 20px;
}

.selected {
  border-bottom: 2px solid !important;
}

.carrier {
  width: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: skew(333deg);
}

.carriersCLogos {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.carrierLogo {
  width: 100px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.carrierLogo img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1024px) {
  .heading h3 {
    font-size: 18px;
  }

  .content p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .icContent {
    padding: 20px 60px;
  }

  .heading h3 {
    font-size: 14px;
  }

  .content p {
    font-size: 12px;
  }

  .category-buttons button {
    font-size: 16px;
  }
}

@media only screen and (max-width: 430px) {
  .icContent {
    padding: 20px 20px;
  }

  .iccDesc h2 {
    font-size: 20px !important;
  }

  .heading {
    width: 84%;
  }

  .heading h3 {
    font-size: 12px;
  }

  .content {
    transform: none;
  }

  .content p {
    font-size: 10px;
    transform: none;
  }

  .carriersCLogos {
    gap: 15px;
  }
}