@import url('https://fonts.googleapis.com/css2?family=Slabo+13px&display=swap');

@font-face {
  font-family: 'Segoe UI';
  src: url('./assets//Fonts/Segoe\ UI.ttf') format('truetype');
}

@font-face {
  font-family: 'Segoe UI Bold';
  src: url('./assets//Fonts/Segoe\ UI\ Bold.ttf') format('truetype');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    /* font-family: 'Slabo 13px', serif; */
    /* font-family: "Prata", Sans-serif; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* color: #354256; */
    color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-size: 36px;
  padding-bottom: 5px;
  color: #0f4d92;
  /* border-bottom: 2px solid #f0d126; */
}

.quote-btn {
  border-radius: 1.6em;
  min-width: 150px;
  padding: 0.7em 2.1em;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  line-height: 1.5em;
  /* background-color: #354256; */
  background-color: #0f4d92;
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  border: none;
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
}

.quote-btn:hover {
  box-shadow: 11px 9px 20px rgba(53, 66, 86, 0.3);
  transform: translateY(-3px); 
}

@media (max-width: 1024px) {
  h2 {
    font-size: 28px !important;
  }
}

@media (max-width: 768px) {
  h2 {
    font-size: 24px !important;
  }
}

@media (max-width: 430px) {
  h3 {
    font-weight: 400;
    line-height: 1.4;
  }
}