.privacy {
  display: flex;
  flex-direction: column;
  padding: 0 100px;
  margin-top: 90px;
}

.privacy-intro h2 {
  text-align: center;
}

.privacy-content h3{
  font-size: 28px;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .privacy {
    padding: 0 80px;
  }
}

@media (max-width: 430px) {
  .privacy {
    padding: 0 20px;
  }

  .privacy-content h3 {
    font-weight: 600;
    font-size: 22px;
  }
}