.getAQuote {
  display: flex;
  flex-direction: column;
  margin-top: 85px;
  background-color: #0f4d92;
}

.getAQuote h1 {
  font-size: 36px;
  align-self: center;
  padding-bottom: 5px;
  /* border-bottom: 3px solid #f0d126; */
}

.getaQuoteForm {
  padding: 20px 80px;
  /* background: antiquewhite; */
  background-color: #0f4d92;
  color: #fff;
  margin: 10px 100px;
}

.contactInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  padding: 5px 0;
  border-bottom: 1px solid;
  border-top: 1px solid;
}

.contactInfo label {
  font-weight: 800;
  /* padding-left: 5px;
  border-left: 3px solid red; */
}

.contactInfo input, #getQuoteTextArea {
  width: 98.5%;
  padding: 10px;
  border-radius: 10px;
}

label,
input,
#dropdown,
textarea {
  font-size: 1rem;
  font-family: "Prata", Sans-serif;
}

.selectorsDiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid;
}

.dropDown-selector {
  display: flex;
  width: 40%;
  align-items: baseline;
  justify-content: space-between;
}

label {
  font-weight: 800;
  /* padding-left: 5px;
  border-left: 3px solid red; */
}

#dropdown {
  width: 200px;
  padding: 3px;
  background-color: aliceblue;
  border-radius: 10px;
}

.radio-selector {
  display: flex;
  width: 45%;
  align-items: center;
  justify-content: space-between;
}

.radio {
  display: flex;
  align-items: baseline;
  font-weight: 400;
  border: none;
}

.textArea {
  width: 100%;
}


.address-inputs {
  display: flex;
  justify-content: space-between;
}

.address-inputs input {
  width: 20%;
}

/* #getQuoteTextArea {
  width: 98.5%;
} */

textarea::placeholder,
input::placeholder {
  font-style: italic;
}

.getQuoteStyle {
  height: auto;
}

.getQuoteStyle .wcuList {
  grid-template-columns: repeat(5, 1fr);
}

.getQuoteStyle .wcuList .wcuListItem {
  align-items: center;
}

.success-message {
  display: flex;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: antiquewhite;
  width: 80%;
  font-size: 24px;
}

@media (max-width: 1024px) {
  .getaQuoteForm {
    margin: 10px 80px;
  }

  .getAQuote h1 {
    font-size: 28px;
  }

  label,
  input,
  textarea,
  #dropdown {
    font-size: 1rem;
  }

  .dropDown-selector {
    align-items: center;
  }

  .getQuoteStyle .wcuList .wcuListItem h4{
    font-size: 16px;
  }
  .success-message {
    top: 18%;
  }
}

@media (max-width: 768px) {
  .getaQuoteForm {
    margin: 10px 60px;
    padding: 20px 60px;
  }

  .selectorsDiv {
    flex-direction: column;
    gap: 15px;
  }

  .getAQuote h1 {
    font-size: 22px;
  }

  .dropDown-selector,
  .radio-selector {
    width: auto;
  }

  label,
  input,
  textarea,
  #dropdown {
    font-size: 0.8rem;
  }

  .getQuoteStyle .wcuList {
    grid-template-columns: repeat(1, 1fr);
  }

  .getQuoteStyle .wcuList .wcuListItem {
    height: auto;
  }
}

@media (max-width: 430px) {
  .getaQuoteForm {
    margin: 10px 20px;
    padding: 20px 20px;
  }

  .getAQuote h1 {
    font-size: 1.2rem;
  }

  .address-inputs {
    flex-direction: column;
  }

  .address-inputs input {
    width: 98.5%;
  }
}